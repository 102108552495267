import React from 'react'

const Heading = ({children, size, className}) => {
    switch(size) {
        case 'h1':
            return <h1 className={`text-5xl lg:text-7.5xl leading-none tracking-[-0.03em] mb-3 ${className ? className : ""}`}>{children}</h1>
        case 'h2':
            return <h2 className={`text-4xl lg:text-4.5xl leading-[1.125] tracking-[-0.03em] mb-3 ${className ? className : ""}`}>{children}</h2>
        case 'h3':
            return <h3 className={`text-3xl lg:text-4xl leading-[1.125] tracking-[-0.03em] mb-3 ${className ? className : ""}`}>{children}</h3>
        case 'h4':
            return <h4 className={`text-xl lg:text-2xl leading-[1.2] tracking-[-0.03em] mb-3 ${className ? className : ""}`}>{children}</h4>
        case 'h5':
            return <h5 className={`text-sm leading-[1.2] tracking-[-0.03em] mb-3 ${className ? className : ""}`}>{children}</h5>
        case 'h6':
            return <h6 className={`text-xs leading-[1.42] tracking-[-0.03em] mb-3 ${className ? className : ""}`}>{children}</h6>
        default:
            return null
    }
}

export default Heading