module.exports = {
  siteMetadata: {
    title: 'Generation Media',
    author: {
      name: 'The Bigger Boat',
      summary: 'TBB Gatsby / Storyblok',
    },
    description: 'A starter template for building hopes and dreams.',
    siteUrl: 'https://www.generationmedia.com',
  },
  plugins: [
    'gatsby-plugin-postcss',
    {
    resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'MrUT1EIPg65EcXHxcPrZHQtt',
        homeSlug: 'home',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft'
      }
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: "GTM-M2VM5CQ",
        includeInDevelopment: false,
        defaultDataLayer: { platform: "gatsby" },
        enableWebVitalsTracking: true,
      },
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: [
          "UA-30845210-1" // Google Analytics / GA
        ],
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: true,
        },
      },
    },
    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rule: {
          include: /images/
        }
      }
    },
    'gatsby-plugin-root-import',
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-plugin-netlify-identity-gotrue`,
      options: {
        url: `https://generation-media.netlify.app`
      }
    },
    `gatsby-plugin-force-trailing-slashes`,
    `gatsby-plugin-sitemap`,
    {
      resolve: `gatsby-plugin-react-helmet-canonical-urls`,
      options: {
        siteUrl: `https://www.generationmedia.com`,
        noTrailingSlash: false,
        },
    },
    {
      resolve: `gatsby-plugin-netlify`,
      options: {
        headers: {
          "/*": [
            "Access-Control-Allow-Origin: *",
            "Content-Security-Policy: frame-ancestors https://app.storyblok.com/",
            "X-Frame-Options: SAMEORIGIN",
          ],
        },
      },
    },
  ]
};
