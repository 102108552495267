import React from "react"
import ButtonArrow from "src/images/button_arrow.svg"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { Link } from "gatsby"

const MembersLogin = () => {

    const identity = useIdentityContext()

    const renderRoleDescription = (roles) => {
        if( roles?.includes('admin')) {
            return "Administrator"
        }
        if( roles?.includes('member')) {
            return "Members Login"
        }
        return "Awaiting Approval"
    }

    return (      
        identity.user ?
        <div>
            <div className="text-sm font-light">     
                {renderRoleDescription(identity.user?.app_metadata?.roles)}
            </div>
            <div className="text-3xl flex duration-300 text-green">        
                {identity.user.user_metadata.full_name}
            </div> 
            <button className="text-xs font-light underline cursor-pointer" onClick={identity.logout}>Log out</button>
        </div>        
        :
        <Link to="/login/" className="group cursor-pointer">
            <div className="text-sm font-light">     
                Got a log in?        
            </div>
            <div className="text-3xl flex duration-300 text-green group-hover:text-darkgreen">        
                Members             
                <ButtonArrow className="transition-transform duration-300 group-hover:rotate-45 ml-2 mt-2 fill-current" width="18" height="19" />
            </div> 
        </Link>
    )

}

export default MembersLogin