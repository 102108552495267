import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import Heading from "../components/Heading"
import ButtonArrow from "src/images/button_arrow.svg"

import { useIdentityContext } from 'react-netlify-identity-gotrue'

const AuthOverlay = () => {
  const identity = useIdentityContext()

  const { register, handleSubmit, formState: { errors }} = useForm()
  const [formError, setFormError] = useState()
  const [formProcessing, setFormProcessing] = useState(false)
  const [forceShowOverlay, setForceShowOverlay] = useState(false)

  useEffect(() => {
    if (identity.provisionalUser) {
      setForceShowOverlay('Please check your email for an account confirmation email!')
      const timeoutId = setTimeout(() => setForceShowOverlay(false), 5000)
      return () => clearTimeout(timeoutId)
    }
  }, [identity.provisionalUser])

  async function fetchUser(identity) {
    if( identity.user ) {
      try {
          const response = await identity.authorizedFetch('/.netlify/functions/get-user', {
              method: 'POST',
              body: JSON.stringify({
                user_id: identity.user.id
              })            
          })
          const json = await response.json()
          if( json.app_metadata.roles.includes('member') && !identity.app_metadata.roles ) {
            identity.logout()
          }
      } catch (e) {
          console.error(e)
      }
    }
  }      

  useEffect(() => {
    fetchUser(identity)
  }, [identity, fetchUser])

  const onSubmit = async (data) => {
    setFormProcessing(true)
    setFormError()

    await identity.completeUrlTokenTwoStep(data)
      .catch(_ => setFormError('Having an issue.. please try later'))

    setFormProcessing(false)
  }

  return (
    <>
      {(identity.urlToken || forceShowOverlay) &&
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">

                {identity.urlToken?.type === "confirmation" &&
                <p className="block text-base font-light mb-2">Confirming User...</p>
                }
                {forceShowOverlay &&
                    <p className="block text-base font-light mb-2">{forceShowOverlay}</p>
                }
                {(identity.urlToken?.type === "passwordRecovery" || identity.urlToken?.type === "invite") &&
                <>
                    <Heading size="h4">Reset Password</Heading>
                    <form className="pt-6" onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-6">
                            <label htmlFor="password" className="block text-base font-light mb-2">
                                New Password
                            </label>
                            <input
                                {...register('password', { required: true })}
                                className={`appearance-none border-[#C7C7C7] w-full py-2 px-3 mb-3 focus:outline-none focus:border-orange ${formProcessing && 'opacity-75'}`}
                                disabled={formProcessing}
                                name="password"
                                type="password"
                                placeholder="******************">
                            </input>
                            {errors.password && <p className="text-red-500 text-xs italic">Password is required</p>}
                        </div>
                        <div className="flex items-center justify-between">
                            <button type="submit" className={`text-2xl flex duration-300 text-orange group hover:text-darkorange cursor-pointer ${formProcessing && 'opacity-50 cursor-not-allowed'}`}>        
                                Set New Password
                                <div className="text-orange group-hover:text-darkorange">              
                                    <ButtonArrow className="transition-transform duration-300 group-hover:rotate-45 ml-2 mt-2 fill-current" width="18" height="19" />
                                </div>
                                disabled={formProcessing}
                            </button>                         
                        </div>
                        {formError &&
                            <div className="pt-2">
                                <p className="text-red-500 text-xs italic">Oops! We're having trouble right now.</p>
                            </div>
                        }
                    </form>
                </>
                }
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default AuthOverlay
