import React from 'react'
import { Link } from 'gatsby'
import resolveLink from 'src/utils/resolveLink'
import ButtonArrow from 'src/images/button_arrow.svg'

const Button = ({ children, className, to, target, iconClass }) => {

    const classes = `group inline-flex justify-center items-center font-medium lg:text-xl text-lg !leading-6 cursor-pointer pr-6 py-3 line-crop ease-in-out duration-300 tracking-[-1px] ${className ? className : ""}`
    const iconClasses = `w-[12px] transition-transform duration-300 ml-2 group-hover:rotate-45 fill-current ${iconClass ? iconClass : ''}`

    return (
      // If there is no target/link just output a div. This is to prevent the nested <a> problem
      !to ?
        <div 
          className={`${classes}`}
          >
            {children}
            <ButtonArrow className={`${iconClasses}`}/>
        </div>
      // If the target is a non-gatsby target
      : to.includes('tel:') || to.includes('mailto:') || target === '_blank' ? 
        <a 
          className={`${classes}`} 
          href={to}
          target={target}
          >
            {children}
            <ButtonArrow className={`${iconClasses}`}/>
        </a>
      // Default
      :
        <Link 
          className={`${classes}`}
          to={to === 'home' ? '/' : resolveLink(to)}
          >
            {children}
            <ButtonArrow className={`${iconClasses}`}/>
        </Link>
    )
}

export default Button
