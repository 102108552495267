import { useContext } from 'react'
import { PageContext } from 'src/utils/context'
import { graphql, useStaticQuery } from 'gatsby';

// This will grab all of the fields in "Settings" depending on the language.
const GetSettings = () => {
  
    // Grab the initial data
    const data = useStaticQuery(graphql`
        {
            settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
                edges {
                    node {
                    name
                    full_slug
                    content
                    }
                }
            }
        }
    `)

    // Get the location from the page context. Refer to page.js, index.js and editor.js
    const location = useContext(PageContext).location

    // This was originally in Layout.js
    // Set the pathname, search and empty language let
    let { pathname } = location
    let { search } = location
    let language

    // Check if it's in the editor. Because pathname i<Page blok={this.state.story.content} />s not correct in the editor we need to do a check like this
    if (search.includes('_storyblok')) {
        // Isolate the ?path= in .search. Output will be en or de
        language = search.substring(
            search.lastIndexOf("?path=") + 6, 
            search.lastIndexOf("/")
        )
    // If it's not the editor then do what it did before - check the pathname
    } else {
        language = pathname.split("/")[1].replace('/', '')
    }
    let activeLanguage = ['de', 'en'].includes(language) ? language : 'en'
    let correctSetting = data.settings.edges.filter(edge => edge.node.full_slug.indexOf(activeLanguage) > -1)
    let hasSetting = correctSetting && correctSetting.length ? correctSetting[0].node : {}
    let content = typeof hasSetting.content === 'string' ? JSON.parse(hasSetting.content) : hasSetting.content
    let parsedSetting = Object.assign({}, content, {content: content}, {activeLanguage: activeLanguage})
        
    return parsedSetting

}

export default GetSettings